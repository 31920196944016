body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vh-height-100{
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}

.message-container {
   max-width:650px;
   width:100%;
   border-radius:6px;
   background-color: rgb(33, 37, 41, 0.95);
   transition:opacity .2s;
}

.height-100 {
  min-height: 100px;
}

.set-center {
  align-items: center;
  justify-content: center;
}

.img {
  background-position: center;
}

.logo a {
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7em;
}

.link-white a, .link-white a:hover {
  color: white;
  text-decoration: none;
}
